@import '../../styles/variables';

.panel {
  :global(.ant-collapse-header) {
    cursor: default !important;
  }
}

.content {
  display: inline-block;

  + .action {
    margin-top: $spacing;
  }
}
