.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  line-height: 1;
  color: #fff;
}

.image {
  display: block;
  width: 100%;
}

.text {
  margin-top: 4px;
  font-size: 11px;
}
