@import './styles/variables';

.root {
  min-height: 100%;
}

.header {
  position: fixed;
  z-index: 2;
  width: 100%;
}

.headerContent {
  max-width: $maxWidth;
  margin: 0 auto;
}

.logoWrapper {
  float: left;
  display: flex;
  align-items: center;
  width: 140px;
  height: $headerHeight;
  margin-right: $spacing * 2;
}

.menu {
  line-height: $headerHeight;
}

.body {
  width: 100%;
  max-width: $maxWidth;
  margin: $headerHeight auto 0;
}

.content {
  min-height: 380px;
  padding: $spacing * 1.5;
  background: #fff;
}

.footer {
  text-align: center;
}
