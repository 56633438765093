@import '../../../styles/variables';

.field {
  min-width: 200px;
  padding-bottom: 0 !important;
  margin-bottom: $spacing;

  &:last-child {
    margin-bottom: 0;
  }
}
