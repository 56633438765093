.resizeHandles {
  > div {
    border-radius: 10px;
    background: rgba(red, 0.25);
    transition: background 100ms;

    &:hover {
      background: rgba(red, 0.5);
    }
  }
}
