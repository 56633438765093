.root {
  display: inline-block;
  padding: 0.2em 0.4em 0.1em;
  border: 1px solid rgba(black, 0.06);
  border-radius: 3px;
  background: rgba(black, 0.06);
  font-size: 85%;
  white-space: pre-wrap;
  vertical-align: middle;

  pre {
    margin: 0;
  }
}
